import { useBootstrap } from '@admin/hooks';
import { $fetch, $post, setToken, setChannels, getBaseURL } from '@/services';

const { setBootstrapData } = useBootstrap();

export function login(data) {
  return $post(`/login`, data).then(res => {
    setToken(res.token);
    return res;
  });
}

export function bootstrap(data) {
  return $post(`/refresh`, data).then(res => {
    setBootstrapData(res);
    return res;
  });
}

export function changePassword(data) {
  return $post(`/changePassword`, data);
}

export function loadPublicBootstrap() {
  return $fetch(`/public/bootstrap`);
}

export function initChannels() {
  return $fetch('/public/channel').then(setChannels);
}

export function resetPasswordRequest(data) {
  return $post('/resetPasswordRequest', data);
}

export function resendActiveMail(data) {
  return $post('/resendActiveMail', data);
}

export function openVirtualAccount(data) {
  return $post('/demoAccountOpens', data);
}

export function openRealAccount(data) {
  return $post('/realAccountSubmit', data);
}

export function activateRealAccount(data) {
  return $post('/realAccountActive', data);
}

/**
 * @return
 *  成功: 延时毫秒数
 *  失败: timeout or ajax error
 */
export function pingChannel(channel, timeout = 10 * 1000) {
  // return Promise.reject('timeout');
  // if (channel.startsWith('p2')) return Promise.reject('timeout');
  const api = '/public/ping';
  if (!channel) throw 'pingChannel: channel is required';
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => reject('timeout'), timeout);
    const startTime = Date.now();
    $fetch(getBaseURL(channel) + api)
      .then(() => {
        resolve(Date.now() - startTime);
      })
      .catch(reject)
      .finally(() => {
        clearTimeout(timer);
      });
  });
}
